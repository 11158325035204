import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
// import Login from './pages/login';
// import Register from './pages/register';
// import Recover from './pages/recover';
// import Modules from './pages/modules';
// import AskExpert from './pages/askExpert';
// import Question from './pages/question';
// import Submit from './pages/submit';
// import Video from './pages/video';
// import Resources from './pages/resources';
// import Assessment from './pages/assessment';
// import Certificate from './pages/certificate';
// import Faq from './pages/faq';
// import DashboardWrapper from './pages/dashboardWrapper';
// import About from './pages/about';
// import AssessmentAnswer from './pages/AssessmentAnswer';
// import PrivacyNotice from './components/PrivacyNotice';
// import StateManager from './pages/StateManager';
import { useEffect } from 'react';
// import SurveyPreSelect from './pages/survey/register_survey/SurveyPreSection1';
// import SurveyWrapper from './components/SurveyWrapper';
// import SurveyPreSection2 from './pages/survey/register_survey/SurveyPreSection2';
// import SurveyPreSection3 from './pages/survey/register_survey/SurveyPreSection3';
// import SurveyPreSection4 from './pages/survey/register_survey/SurveyPreSection4';
// import SurveyPreSection5 from './pages/survey/register_survey/SurveyPreSection5';
// import SurveyPreSection6 from './pages/survey/register_survey/SurveyPreSection6';

// import SurveyPostSection1 from './pages/survey/post-module-survey/SurveyPostSection1';
// import SurveyPostSection2 from './pages/survey/post-module-survey/SurveyPostSection2';
// import SurveyPostSection3 from './pages/survey/post-module-survey/SurveyPostSection3';

// import ThreeMonthsSurveySection1 from './pages/survey/3month-survey/ThreeMonthsSurveySection1';
// import ThreeMonthsSurveySection2 from './pages/survey/3month-survey/ThreeMonthsSurveySection2';
// import ThreeMonthsSurveySection3 from './pages/survey/3month-survey/ThreeMonthsSurveySection3';
// import ThreeMonthsSurveySection4 from './pages/survey/3month-survey/ThreeMonthsSurveySection4';
// import ThreeMonthsSurveySection5 from './pages/survey/3month-survey/ThreeMonthsSurveySection5';
// import ThreeMonthsSurveySection6 from './pages/survey/3month-survey/ThreeMonthsSurveySection6';
// import ThreeMonthsSurveySection7 from './pages/survey/3month-survey/ThreeMonthsSurveySection7';
// import ThreeMonthsSurveySection8 from './pages/survey/3month-survey/ThreeMonthsSurveySection8';
// import ThreeMonthsSurveySection9 from './pages/survey/3month-survey/ThreeMonthsSurveySection9';
import StayTunned from './pages/StayTunned';

function App() {
  // useEffect(() => {
  //   const token = localStorage.getItem('dlp-hcp-doctor-token');
  //   const url = window.location.pathname;
  //   if (
  //     url === '/login' ||
  //     url === '/register' ||
  //     url === '/recover' ||
  //     url.includes('survey/post3months')
  //   ) {
  //     return;
  //   } else {
  //     if (!token) {
  //       window.location.href = '/login';
  //     }
  //   }
  // }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index path="/" element={<StayTunned />} />
          <Route path="*" element={<Navigate to="/" />}></Route>
          {/* <Route index path="/login" element={<Login />} />
          <Route index path="/register" element={<Register />} />
          <Route index path="/recover" element={<Recover />} />
          <Route
            index
            path="/modules"
            element={
              <StateManager>
                <Modules />
              </StateManager>
            }
          />
          <Route
            index
            path="/ask-the-expert"
            element={
              <StateManager>
                <AskExpert />
              </StateManager>
            }
          />
          <Route
            index
            path="/question"
            element={
              <StateManager>
                <Question />
              </StateManager>
            }
          />
          <Route
            index
            path="/ask-the-expert/submit"
            element={
              <StateManager>
                <Submit />
              </StateManager>
            }
          />
          <Route index path="/faq" element={<Faq />} />
          <Route index path="/about" element={<About />} />

          <Route index path="/privacy" element={<PrivacyNotice />} />

          <Route
            index
            path="/video"
            element={
              <DashboardWrapper>
                <Video />
              </DashboardWrapper>
            }
          />
          <Route
            index
            path="/resources"
            element={
              <DashboardWrapper>
                <Resources />
              </DashboardWrapper>
            }
          />
          <Route
            index
            path="/assessment"
            element={
              <DashboardWrapper>
                <Assessment />
              </DashboardWrapper>
            }
          />

          <Route
            index
            path="/assessment/answer"
            element={
              <DashboardWrapper>
                <AssessmentAnswer />
              </DashboardWrapper>
            }
          />

          <Route
            index
            path="/certificate"
            element={
              <DashboardWrapper>
                <Certificate />
              </DashboardWrapper>
            }
          />

          <Route
            exact
            path="/survey/pre/one"
            element={
              <SurveyWrapper>
                <SurveyPreSelect />
              </SurveyWrapper>
            }
          />
          <Route
            exact
            path="/survey/pre/two"
            element={
              <SurveyWrapper>
                <SurveyPreSection2 />
              </SurveyWrapper>
            }
          />

          <Route
            exact
            path="/survey/pre/three"
            element={
              <SurveyWrapper>
                <SurveyPreSection3 />
              </SurveyWrapper>
            }
          />
          <Route
            exact
            path="/survey/pre/four"
            element={
              <SurveyWrapper>
                <SurveyPreSection4 />
              </SurveyWrapper>
            }
          />
          <Route
            exact
            path="/survey/pre/five"
            element={
              <SurveyWrapper>
                <SurveyPreSection5 />
              </SurveyWrapper>
            }
          />
          <Route
            exact
            path="/survey/pre/six"
            element={
              <SurveyWrapper>
                <SurveyPreSection6 />
              </SurveyWrapper>
            }
          />

          <Route
            index
            path="/survey/post/one"
            element={
              <SurveyWrapper>
                <SurveyPostSection1 />
              </SurveyWrapper>
            }
          />
          <Route
            index
            path="/survey/post/two"
            element={
              <SurveyWrapper>
                <SurveyPostSection2 />
              </SurveyWrapper>
            }
          />
          <Route
            index
            path="/survey/post/three"
            element={
              <SurveyWrapper>
                <SurveyPostSection3 />
              </SurveyWrapper>
            }
          />

          <Route
            index
            path="/survey/post3months/one"
            element={
              <SurveyWrapper>
                <ThreeMonthsSurveySection1 />
              </SurveyWrapper>
            }
          />
          <Route
            index
            path="/survey/post3months/two"
            element={
              <SurveyWrapper>
                <ThreeMonthsSurveySection2 />
              </SurveyWrapper>
            }
          />
          <Route
            index
            path="/survey/post3months/three"
            element={
              <SurveyWrapper>
                <ThreeMonthsSurveySection3 />
              </SurveyWrapper>
            }
          />
          <Route
            index
            path="/survey/post3months/four"
            element={
              <SurveyWrapper>
                <ThreeMonthsSurveySection4 />
              </SurveyWrapper>
            }
          />
          <Route
            index
            path="/survey/post3months/five"
            element={
              <SurveyWrapper>
                <ThreeMonthsSurveySection5 />
              </SurveyWrapper>
            }
          />
          <Route
            index
            path="/survey/post3months/six"
            element={
              <SurveyWrapper>
                <ThreeMonthsSurveySection6 />
              </SurveyWrapper>
            }
          />
          <Route
            index
            path="/survey/post3months/seven"
            element={
              <SurveyWrapper>
                <ThreeMonthsSurveySection7 />
              </SurveyWrapper>
            }
          />
          <Route
            index
            path="/survey/post3months/eight"
            element={
              <SurveyWrapper>
                <ThreeMonthsSurveySection8 />
              </SurveyWrapper>
            }
          />
          <Route
            index
            path="/survey/post3months/nine"
            element={
              <SurveyWrapper>
                <ThreeMonthsSurveySection9 />
              </SurveyWrapper>
            }
          />

          <Route path="*" element={<Navigate to="/login" />}></Route> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}
// DLP 3-month Post-training Survey
export default App;
