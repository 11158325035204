import React from 'react';
import allLogo from '../assets/logos2.png';
import dlpLogo from '../assets/dlp_logo.png';
function header() {
  return (
    <div className="top-nav">
      <div className="d-flex align-items-center py-1">
        {/* <div className="mx-3">
          <div>
            <img style={{ width: '95px' }} src={dlpLogo} alt="DLP Logo" />
          </div>
        </div> */}

        <div className="show-logo-in-mobile">
          <img
            style={{ width: '62vw', objectFit: 'contain' }}
            src={allLogo}
            alt="All Logo"
          />
        </div>

        <div className="hide-logo-in-mobile">
          <img
            style={{ width: '95px', marginRight: '2rem' }}
            src={dlpLogo}
            alt="DLP Logo"
          />
          <img
            style={{ width: '40vw', objectFit: 'contain' }}
            src={allLogo}
            alt="All Logo"
          />
        </div>
      </div>
    </div>
  );
}

export default header;
