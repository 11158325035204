import React from 'react';
import Header from '../components/header';
import olcBanner from '../assets/olc_banner_no_logo.png';
import lock from '../images/latest/lock.png';

function StayTunned() {
  //
  return (
    <div className="main-container">
      <Header />
      {/* <h1
        className="register-container fw-bold mt-5 "
        style={{ color: 'red', textDecoration: 'underline' }}
      >
        The DLP Online Course is now closed.
      </h1> */}
      <div style={{ textAlign: 'center', color: '#fff' }}>
        <img
          className="olc-banner"
          style={{ marginTop: '-1rem' }}
          src={olcBanner}
          alt="banner"
        />

        <div>
          <img
            src={lock}
            alt="banner"
            style={{ objectFit: 'contain', width: '50px', marginTop: '1rem' }}
          />
        </div>
        <h1>The DLP Online Course is now closed.</h1>
        <h2
          className="register-container text-white mt-5"
          style={{ marginLeft: '5%', marginRight: '5%' }}
        >
          Stay tuned for the next opening of the DLP Online Course and gain
          valuable insights on how to make a positive impact on your patient’s
          life.
        </h2>
      </div>
    </div>
  );
}

export default StayTunned;
